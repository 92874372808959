import {
    UilCalender,
    UilCalendarAlt,
    UilCoins,
    UilFileBookmarkAlt,
    UilEllipsisV,
    UilEnvelope,
    UilFile,
    UilGift,
    UilMedal,
    UilPhone,
    UilTachometerFastAlt,
    UilUser,
    UilUsersAlt,
    UilImage,
    UilPlus,
    UilPlug,
    UilTrashAlt,
    UilEdit,
    UilAlignJustify,
    UilPen,
    UilCheck,
    UilAngleDown,
    UilAngleRight,
    UilAngleLeft,
    UilAngleUp,
    UilCopy,
    UilSpinner,
    UilPlusCircle,
    UilListUl,
    UilTimes,
    UilBuilding,
    UilCreditCard,
    UilCircle,
    UilMapMarker,
    UilTimesCircle,
    UilCardAtm,
    UilTransaction,
    UilMapMarkerAlt,
    UilSync,
    UilLocationArrow,
    UilStore,
    UilLabel,
    UilObjectGroup,
    UilPower,
    UilGlobe,
    UilUsdSquare,
    UilChartBar,
    UilAngleLeftB,
    UilFileCheckAlt,
    UilMapMarkerInfo,
    UilHome,
    UilInfoCircle,
    UilUserPlus,
    UilCheckCircle,
    UilExclamationCircle,
    UilLayerGroup,
    UilClock,
    UilTruck,
    UilFileExport,
    UilEnter,
    UilAward,
    UilPlay,
    UilTrophy,
    UilEye,
    UilArrowUp,
    UilGold,
    UilEyeSlash,
    UilDollarAlt,
    UilEnvelopeOpen,
    UilCommentsAlt,
    UilMessage,
    UilTagAlt,
    UilHeart,
    UilUsdCircle,
    UilDashboard,
    UilPostcard,
    UilMoneyStack,
    UilMoneyBillStack,
    UilDollarSign,
    UilHistoryAlt,
    UilFileImport,
    UilSyncExclamation,
    UilExclamationTriangle,
    UilChart,
    UilSlidersV,
    UilSetting,
    UilLockAccess,
    UilShare,
    UilBell,
    UilArrowCircleDown,
    UilArrowCircleUp,
    UilWrench,
    UilTag,
    UilBars,
    UilStar,
    UilLaptop,
    UilMobileAndroidAlt,
    UilMars,
    UilVenus,
    UilHourglass,
    UilShoppingBasket,
    UilHeartbeat,
    UilHeartRate,
    UilBill,
    UilArrowLeft,
    UilStepForward,
    UilKeyholeCircle,
    UilArrowCircleRight,
    UilPhoneAlt,
    UilClipboardAlt,
    UilUserCheck,
    UilUserCircle,
    UilPostStamp,
    UilArrowDown,
    UilPrint,
    UilThLarge,
    UilFacebook,
    UilFacebookMessenger,
    UilChatInfo,
    UilUserSquare,
    UilAngleRightB,
    UilProcess,
    UilImport,
    UilSearch,
    UilSearchAlt,
    UilLock,
    UilMinusCircle,
    UilSuitcase,
    UilFileGraph,
    UilChartLine,
    UilTelegramAlt,
    UilFilter,
    UilHeartMedical,
    UilEnvelopeUpload,
    UilCommentUpload,
    UilLocationPinAlt,
    UilCalculatorAlt,
    UilHistory,
    UilFileAlt,
    UilObjectUngroup,
    UilClipboardNotes,
    UilFilterSlash,
    UilArchive,
    UilMoneyBill,
    UilChannel,
    UilExport,
    UilFidgetSpinner,
    UilLifeRing,
    UilExternalLinkAlt,
    UilShieldCheck,
} from "@iconscout/react-unicons";

export {
    UilCalender as faCalendar,
    UilImport as faImport,
    UilCalendarAlt as faCalendarAlt,
    UilCoins as faCoins,
    UilFileBookmarkAlt as faFileBookmarkAlt,
    UilEllipsisV as faEllipsisV,
    UilEnvelope as faEnvelope,
    UilFile as faFile,
    UilGift as faGift,
    UilMedal as faMedal,
    UilPhone as faPhone,
    UilTachometerFastAlt as faTachometerAlt,
    UilUser as faUser,
    UilImage as faImage,
    UilPlus as faPlus,
    UilPlug as faPlug,
    UilUsersAlt as faUsers,
    UilTrashAlt as faTrash,
    UilArchive as faArchive,
    UilPen as faPen,
    UilEdit as faEdit,
    UilAlignJustify as faAlignJustify,
    UilCheck as faCheck,
    UilAngleDown as faAngleDown,
    UilAngleRight as faAngleRight,
    UilAngleLeft as faAngleLeft,
    UilAngleUp as faAngleUp,
    UilCopy as faCopy,
    UilSpinner as faSpinner,
    UilPlusCircle as faPlusCircle,
    UilListUl as faListAlt,
    UilTimes as faTimes,
    UilBuilding as faBuilding,
    UilCreditCard as faCreditCard,
    UilCircle as faCircleNotch,
    UilMapMarker as faMap,
    UilTimesCircle as faTimesCircle,
    UilCardAtm as faCardAtm,
    UilTransaction as faTransaction,
    UilMapMarkerAlt as faMapMarkerAlt,
    UilSync as faSync,
    UilLocationArrow as faLocationArrow,
    UilStore as faStore,
    UilLabel as faLabel,
    UilObjectGroup as faObjectGroup,
    UilPower as faPower,
    UilGlobe as faGlobe,
    UilUsdSquare as faUsdSquare,
    UilChartBar as faChartBar,
    UilAngleLeftB as faAngleLeftB,
    UilFileCheckAlt as faFileCheckAlt,
    UilMapMarkerInfo as faMapMarkerInfo,
    UilHome as faHome,
    UilInfoCircle as faInfoCircle,
    UilUserPlus as faUserPlus,
    UilCheckCircle as faCheckCircle,
    UilExclamationCircle as faExclamationCircle,
    UilLayerGroup as faLayerGroup,
    UilClock as faClock,
    UilTruck as faDispatch,
    UilFileExport as faFileExport,
    UilEnter as faUndo,
    UilAward as faAward,
    UilPlay as faStart,
    UilTrophy as faTrophy,
    UilEye as faEye,
    UilArrowUp as faArrowUp,
    UilGold as faQuantity,
    UilEyeSlash as faEyeSlash,
    UilDollarAlt as faDollarSign,
    UilEnvelopeOpen as faEnvelopeOpen,
    UilCommentsAlt as faCommentsAlt,
    UilMessage as faMessage,
    UilTagAlt as faTagAlt,
    UilHeart as faHeart,
    UilUsdCircle as faUsdCircle,
    UilDashboard as faDashboard,
    UilPostcard as faPostcard,
    UilMoneyStack as faMoneyStack,
    UilMoneyBillStack as faMoneyBillStack,
    UilDollarSign as faDollarSignAlt,
    UilHistoryAlt as faHistoryAlt,
    UilFileImport as faFileImport,
    UilSyncExclamation as faSyncExclamation,
    UilExclamationTriangle as faExclamationTriangle,
    UilChart as faChart,
    UilSlidersV as faSlidersV,
    UilSetting as faSetting,
    UilLockAccess as faLockAccess,
    UilShare as faShare,
    UilBell as faBell,
    UilArrowCircleDown as faArrowCircleDown,
    UilArrowCircleUp as faArrowCircleUp,
    UilTag as faTag,
    UilWrench as faWrench,
    UilBars as faBars,
    UilStar as faShapes,
    UilLaptop as faLaptop,
    UilMobileAndroidAlt as faMobileAlt,
    UilMars as faMars,
    UilVenus as faVenus,
    UilHourglass as faHourglassHalf,
    UilShoppingBasket as faShoppingBasket,
    UilHeartbeat as faHeartbeat,
    UilHeartRate as faHeartRate,
    UilBill as faHandHoldingUsd,
    UilArrowLeft as faArrowLeft,
    UilStepForward as faStepForward,
    UilKeyholeCircle as faKey,
    UilArrowCircleRight as faArrowAltCircleRight,
    UilPhoneAlt as faPhoneAlt,
    UilClipboardAlt as faClipboardList,
    UilClipboardNotes as faClipboardNotes,
    UilUserCheck as faUserCheck,
    UilUserCircle as faUserCog,
    UilPostStamp as faAddressCard,
    UilArrowDown as faCaretDown,
    UilPrint as faPrint,
    UilThLarge as faThLarge,
    UilFacebook as faFacebook,
    UilFacebookMessenger as faFacebookMessenger,
    UilChatInfo as faWpforms,
    UilUserSquare as faUserSquare,
    UilAngleRightB as faAngleRightB,
    UilProcess as faProcess,
    UilSearch as faSearch,
    UilSearchAlt as faSearchAlt,
    UilMinusCircle as fsMinusCircle,
    UilLock as faLock,
    UilSuitcase as faSuitcase,
    UilFileGraph as faFileGraph,
    UilChartLine as faChartLine,
    UilTelegramAlt as faUilTelegramAlt,
    UilUserCircle as faUilUserCircle,
    UilFilter as faFilter,
    UilFilterSlash as faFilterSlash,
    UilHeartMedical as faHeartMedical,
    UilEnvelopeUpload as faProvider,
    UilCommentUpload as faSender,
    UilLocationPinAlt as faLocationPin,
    UilCalculatorAlt as faCalculatorAlt,
    UilHistory as faHistory,
    UilFileAlt as faFileAlt,
    UilObjectUngroup as faModules,
    UilMoneyBill as faMoneyBill,
    UilChannel as faChannel,
    UilExport as faExport,
    UilFidgetSpinner as faWebhook,
    UilLifeRing as faRing,
    UilExternalLinkAlt as faExternalLink,
    UilShieldCheck as faShieldCheck,
};

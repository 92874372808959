import React from "react";
import { IcIcon } from "@shoutout-labs/shoutout-themes-enterprise";
import { toTitleCase } from "./StringUtils";

const nameIconTemplate = ({ name, icon }) => (
    <div className="d-flex align-items-center">
        {icon && <IcIcon className="mr-2 text-black" size="lg" icon={icon} />}
        <div className="py-1">{name && toTitleCase(name)}</div>
    </div>
);

const columnSortCaret = (order) => {
    return <span className={`${order ? "caret-4-" + order : "order-4"}`} />;
};

const headerFormatterForSort = (column, _colIndex, { sortElement }) => {
    return (
        <div className="d-flex align-items-center">
            {column.text}
            <div className="ml-2">{sortElement}</div>
        </div>
    );
};

const defaultColumnTemplate = ({
    dataField,
    icon,
    displayName,
    sortEnabled = false,
    ...rest
}) => {
    return {
        dataField,
        text: nameIconTemplate({
            name: displayName,
            icon,
        }),
        ...(sortEnabled ? { sort: true, sortCaret: columnSortCaret } : {}),
        ...rest,
    };
};

export { headerFormatterForSort, defaultColumnTemplate };

import { toTitleCase } from "Utils";

const FormCheckOptionsWithYesNoLabels = [
    { label: "Yes", value: true },
    { label: "No", value: false },
];

const Operations = {
    EQUAL: "EQUAL",
    GREATER_THAN: "GREATER_THAN",
    LESS_THAN: "LESS_THAN",
    GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
    LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
};

const OperationsAsOptions = Object.values(Operations).map((op) => ({
    label: toTitleCase(op),
    value: op,
}));

export { FormCheckOptionsWithYesNoLabels, Operations, OperationsAsOptions };
